import React from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import {
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaHome,
  FaFileInvoiceDollar,
  FaWarehouse,
} from 'react-icons/fa'
import Layout from '../components/layout'
// import Image from '../components/image'
import SEO from '../components/seo'
import { CoverageCities } from '../components/coverage-cities'
import { Box, Row, Column } from '../components/ui'
import {
  InternetPricing,
  InternetRuralPricing,
  InternetBusinessPricing,
  // PhonePricing,
  // TelevisionPricing,
} from '../components/pricing'

const breakpoints = [576, 768, 992, 1200]

const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

const BackgroundSection = ({ styles, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "header-bg-new.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <BackgroundImage
          Tag="section"
          css={styles}
          fluid={data.desktop.childImageSharp.fluid}
          backgroundColor={`#040e18`}
        >
          {children}
        </BackgroundImage>
      )
    }}
  />
)

const ServiceCard = ({ icon, title, description }) => (
  <Column
    className="service-card"
    width={230}
    height={310}
    bg="#fff"
    borderRadius={10}
    textAlign="center"
    mx={2}
    css={{ boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.12)' }}
  >
    <Box textAlign="center" px={3} pt={3} pb={1}>
      {icon}
    </Box>
    <Box css={{ textTransform: 'uppercase', fontWeight: 'bold' }} py={2} px={3}>
      {title}
    </Box>
    <Box flex={1} py={2} fontSize={14} px={3}>
      {description}
    </Box>
    <Row
      justifyContent="center"
      alignItems="center"
      fontSize={14}
      fontWeight={600}
      bg="#2fb7f1"
      color="#fff"
      height={36}
      css={{
        textTransform: 'uppercase',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
      }}
    >
      <Link to="/contact">Sign Up Now</Link>
    </Row>
  </Column>
)

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  /* background-position: bottom center; */
  background-position: bottom;
  background-repeat: repeat-y;
  background-size: cover;
`

const IndexPage = () => (
  <Layout routePath="home">
    <SEO title="Home" />

    <StyledBackgroundSection
      styles={{
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        height: 400,
        color: '#fff',
        [mq[1]]: {
          height: 560,
        },
      }}
    >
      <Box
        width={1}
        fontSize={[54, 64]}
        color="#2fb7f1"
        fontWeight="bold"
        textAlign="center"
        pt={[3, 3, 5]}
        css={{
          // outline: '1px solid blue',
          textShadow: '2px 2px #ccc',
          textTransform: 'uppercase',
          wordSpacing: 9999999,

          '.m-w': { display: 'block' },
          '.d-w': { display: 'none' },

          [mq[1]]: {
            wordSpacing: 'inherit',

            '.m-w': { display: 'none' },
            '.d-w': { display: 'block' },
          },
        }}
      >
        <span className="d-w">
          Fast. Local. <span css={{ color: '#727377' }}>Internet.</span>
        </span>
        <span className="m-w">
          Fast Local <span css={{ color: '#727377' }}>Internet</span>
        </span>
      </Box>

      <Row
        //
        pt={[4, 2, 2]}
        justifyContent="center"
        css={
          {
            // outline: '1px solid red',
          }
        }
      >
        <Box
          // color="#2fb7f1"
          color="#555"
          fontSize={22}
          fontWeight={500}
          bg="rgba(255,255,255,0.95)"
          py={2}
          px={4}
          borderRadius={6}
        >
          Starting at $35/month.
        </Box>
      </Row>

      <Column
        pt={[3, 4, 4]}
        // flex={1}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        // mt={[40, 40, 125]}
        css={
          {
            // outline: '1px solid green',
          }
        }
      >
        <Link
          to="/contact"
          css={{
            display: 'inline-block',
            width: 180,
            padding: '6px 0',
            border: 'none',
            color: '#fff',
            backgroundColor: '#2fb7f1',
            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.5)',
            borderRadius: 16,
            fontSize: 14,
            fontWeight: 500,
            textTransform: 'uppercase',
            cursor: 'pointer',
          }}
        >
          Get Started
        </Link>
      </Column>
    </StyledBackgroundSection>

    <Box css={{ backgroundImage: 'linear-gradient(#eee, #fff)', padding: '0 0 56px 0' }}>
      <Box height="3rem" width={1} />

      <Row
        justifyContent="center"
        fontSize={18}
        pb={48}
        css={{ textTransform: 'uppercase', fontWeight: 500 }}
      >
        <span css={{ color: '#2fb7f1' }}>We Cover</span>&nbsp;The Following Areas
      </Row>

      <CoverageCities />

      <Box height="3rem" width={1} />

      <Row
        justifyContent="center"
        fontSize={18}
        pb={48}
        css={{ textTransform: 'uppercase', fontWeight: 500 }}
      >
        <span css={{ color: '#2fb7f1' }}>Choose</span>&nbsp;Your Plan
      </Row>

      <Row
        flexDirection={['column', 'row', 'row']}
        justifyContent="space-between"
        alignItems="center"
        width={1}
        maxWidth={730}
        mx="auto"
        css={{
          '& .pricing-card:not(:last-child)': {
            marginBottom: 42,
          },
          [mq[1]]: {
            '& .pricing-card:not(:last-child)': {
              marginBottom: 0,
            },
          },
        }}
      >
        <InternetPricing />
        <InternetRuralPricing />
        <InternetBusinessPricing />
        {/* <PhonePricing /> */}
        {/* <TelevisionPricing /> */}
      </Row>

      <Box height="5rem" width={1} />
    </Box>

    <Row justifyContent="center" mt={-35}>
      <Row
        flexDirection={['column', 'row', 'row']}
        justifyContent="space-evenly"
        alignItems="center"
        bg="#2fb7f1"
        color="#fff"
        width={1}
        maxWidth={760}
        height={['auto', 70]}
        borderRadius={[0, 0, 15]}
        fontSize={[18, 15]}
        fontWeight={[600, 'normal']}
        css={{ boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.12)' }}
        py={[4, 0]}
      >
        <Row alignItems="center" width={[9 / 12, 'auto']}>
          <Box px={2}>
            <FaMapMarkerAlt />
          </Box>
          <Box>230 8th Ave W, West Fargo, ND 58078</Box>
        </Row>

        <Row alignItems="center" width={[9 / 12, 'auto']}>
          <Box px={2} pt="0.15rem">
            <FaEnvelope />
          </Box>
          <Box>
            <a href="mailto:sales@cassclaywireless.com">sales@cassclaywireless.com</a>
          </Box>
        </Row>
      </Row>
    </Row>

    <Row
      justifyContent="center"
      fontSize={18}
      py={48}
      css={{ textTransform: 'uppercase', fontWeight: 500 }}
    >
      <span css={{ color: '#2fb7f1' }}>Why</span>&nbsp;Should You Choose Us?
    </Row>

    <Row
      flexDirection={['column', 'row', 'row']}
      justifyContent="space-between"
      alignItems="center"
      width={1}
      maxWidth={760}
      mx="auto"
      mb={-36}
      css={{
        zIndex: '999',
        '& .service-card:not(:last-child)': {
          marginBottom: 42,
        },
        [mq[1]]: {
          '& .service-card:not(:last-child)': {
            marginBottom: 0,
          },
        },
      }}
    >
      <ServiceCard
        icon={<FaHome size={60} />}
        title="Residential Service"
        description="Cass Clay Wireless is perfect for your personal internet needs. Low latency for Gaming, Unlimited Devices & is perfect for streaming services. CALL NOW!"
      />
      <ServiceCard
        icon={<FaFileInvoiceDollar size={60} />}
        title="Managed IT Services"
        description={
          <>
            Managed Routers
            <br />
            Managed WiFi
            <br />
            Remote VPN
            <br />
            VoIP
            <br />
            Monitoring
            <br />
            Surveillance
            <br />
            App/Web Development
          </>
        }
      />
      <ServiceCard
        icon={<FaWarehouse size={60} />}
        title="Property Owners"
        description="Position your building for long term success by providing a fast, reliable & cost-effective internet infrastructure solution. Easily add an additional revenue stream to your property"
      />
    </Row>
  </Layout>
)

export default IndexPage
